import React from 'react';
import { arrayOf, bool, func, node, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { LinkedLogo, NamedLink } from '../../../../components';

import Field from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';
import css from './SectionFooter.module.css';
import IconFb from '../../../../components/IconFb/IconFb';
import IconIg from '../../../../components/IconIg/IconIg';
import IconYt from '../../../../components/Iconyt/IconYt';
import IconLl from '../../../../components/IconLk/IconLl';
import { searchPageDefaultUrl } from '../../../../util/uiHelpers';

// The number of columns (numberOfColumns) affects styling

const GRID_CONFIG = [
  { contentCss: css.contentCol1, gridCss: css.gridCol1 },
  { contentCss: css.contentCol2, gridCss: css.gridCol2 },
  { contentCss: css.contentCol3, gridCss: css.gridCol3 },
  { contentCss: css.contentCol4, gridCss: css.gridCol4 },
];
const MAX_MOBILE_SCREEN_WIDTH = 1024;

const getIndex = numberOfColumns => numberOfColumns - 1;

const getContentCss = numberOfColumns => {
  const contentConfig = GRID_CONFIG[getIndex(numberOfColumns)];
  return contentConfig ? contentConfig.contentCss : GRID_CONFIG[0].contentCss;
};

const getGridCss = numberOfColumns => {
  const contentConfig = GRID_CONFIG[getIndex(numberOfColumns)];
  return contentConfig ? contentConfig.gridCss : GRID_CONFIG[0].gridCss;
};

// Section component that's able to show blocks in multiple different columns (defined by "numberOfColumns" prop)
const SectionFooter = props => {
  const {
    sectionId,
    className,
    rootClassName,
    numberOfColumns,
    socialMediaLinks,
    slogan,
    appearance,
    copyright,
    blocks,
    options,
    linkLogoToExternalSite,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };
  const linksWithBlockId = socialMediaLinks?.map(sml => {
    return {
      ...sml,
      blockId: sml.link.platform,
    };
  });

  const showSocialMediaLinks = socialMediaLinks?.length > 0;
  const hasMatchMedia = typeof window !== 'undefined' && window?.matchMedia;
  const isMobileLayout = hasMatchMedia
    ? window.matchMedia(`(max-width: ${MAX_MOBILE_SCREEN_WIDTH}px)`)?.matches
    : true;
  const logoLayout = isMobileLayout ? 'mobile' : 'desktop';

  // use block builder instead of mapping blocks manually
  // console.log('slogan',slogan?.content?.split('\n'))

  const contactData = slogan?.content?.split('\n');
  return (
    // <SectionContainer
    //   as="footer"
    //   id={sectionId}
    //   className={className || css.root}
    //   rootClassName={rootClassName}
    //   appearance={appearance}
    //   options={fieldOptions}
    // >
    //   <div className={css.footer}>
    //     <div className={classNames(css.content, getContentCss(numberOfColumns))}>
    //       <div>
    //         <LinkedLogo
    //           rootClassName={css.logoLink}
    //           logoClassName={css.logoWrapper}
    //           logoImageClassName={css.logoImage}
    //           linkToExternalSite={linkLogoToExternalSite}
    //           layout={logoLayout}
    //         />
    //       </div>
    //       <div className={css.sloganMobile}>
    //         {/* <Field data={slogan} className={css.slogan} /> */}
    //         <a className={css.slogan} href={`mailto:${contactData?.[0]}`}>{contactData?.[0]}</a>
    //         <a className={css.slogan} href={`tel:${contactData?.[1]}`}>{contactData?.[1]}</a>

    //       </div>
    //       <div className={css.detailsInfo}>
    //         <div className={css.sloganDesktop}>
    //         <a className={css.slogan} href={`mailto:${contactData?.[0]}`}>{contactData?.[0]}</a>
    //         <a className={css.slogan} href={`tel:${contactData?.[1]}`}>{contactData?.[1]}</a>

    //           {/* <Field data={slogan} className={css.slogan} /> */}
    //         </div>
    //         {showSocialMediaLinks ? (
    //           <div className={css.icons}>
    //             <BlockBuilder blocks={linksWithBlockId} sectionId={sectionId} options={options} />
    //           </div>
    //         ) : null}

    //       </div>
    //       <div className={classNames(css.grid, getGridCss(numberOfColumns))}>
    //         <BlockBuilder blocks={blocks} sectionId={sectionId} options={options} />
    //       </div>

    //     </div>
    //   </div>
    //   <div className={css.copyRightBlock}>
    //       <Field data={copyright} className={css.copyright} />
    //       </div>
    // </SectionContainer>
    <footer className={css.footer}>
      <div className={classNames(css.container, css.footerContent)}>
        <div className={css.brand}>
          <div>
            <LinkedLogo
              rootClassName={css.logoLink}
              logoClassName={css.logoWrapper}
              logoImageClassName={css.logoImage}
              linkToExternalSite={linkLogoToExternalSite}
              layout={logoLayout}
            />
          </div>
          <p className={css.contact}>
            <a href="mailto:help@careconnect.market">help@careconnect.market</a>
            <br />
            <a href="tel:+14754452785">(+1) 475-445-2785</a>
          </p>
          <div className={css.socialMedia}>
            <a href="https://www.facebook.com/people/CareConnectMarket/61566608907093/" target="_blank" rel="noopener noreferrer">
              <IconFb />
            </a>
            <a href="https://www.instagram.com/careconnect.market/" target="_blank" rel="noopener noreferrer">
              <IconIg />
            </a>
            {/* <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
              <IconYt />
            </a> */}
            <a href="https://www.linkedin.com/company/careconnect-market/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
              <IconLl />
            </a>
          </div>
        </div>
        <div className={css.links}>
          <div>
            {/* <h4>Pages</h4> */}
            <ul>
              <li>
                <a href="/p/about">About Us</a>
              </li>
              <li>
                <NamedLink             name="SearchPage" >Find a Care Community</NamedLink>

                {/* <a href={searchPageDefaultUrl()}>Find a Care Community</a> */}
              </li>
              <li>
                <a href="/l/new">Add your listing</a>
              </li>
            </ul>
          </div>
          <div>
            {/* <h4>Support</h4> */}
            <ul>
              <li>
                <a href="/blog">Blog</a>
              </li>
              <li>
                <a href="/partnership">Partnership</a>
              </li>
              <li>
                <a href="/resources">Resources</a>
              </li>
            </ul>
          </div>
          <div>
            {/* <h4>Legal</h4> */}
            <ul>
              <li>
                <a href="/terms-of-service">Terms of Service</a>
              </li>
              <li>
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
              <li>
                <a href="/p/accessibility">Accessibility Statement</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={css.footerBottom}>
        <p>© 2025 CareConnect, Inc. All rights reserved.</p>
      </div>
    </footer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionFooter.defaultProps = {
  className: null,
  rootClassName: null,
  textClassName: null,
  numberOfColumns: 1,
  socialMediaLinks: [],
  slogan: null,
  copyright: null,
  appearance: null,
  blocks: [],
  options: null,
};

SectionFooter.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  numberOfColumns: number,
  socialMediaLinks: arrayOf(object),
  slogan: object,
  copyright: object,
  appearance: object,
  blocks: arrayOf(object),
  options: propTypeOption,
};

export default SectionFooter;
